import Axios from "axios";

const url =
  "https://2nx1hb1edj.execute-api.us-east-1.amazonaws.com/Stage/NaqashSamMemes/";

export const createMeme = (Content, ImageUrl) => {
  return Axios.post(url, { Content, ImageUrl });
};

export const getAllMemes = () => {
  return Axios.get(url);
};

export const deleteMeme = (id) => {
  return Axios.delete(url + id);
};

export const updateMeme = (id, Content, ImageUrl) => {
  return Axios.put(url + id, { Content, ImageUrl });
};
