import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { resendConfirmation } from "../../services/authService";
import { Box } from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import Alert from "@material-ui/core/Alert";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";

const ResendConfirmation = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar: snackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    onSubmit: async (values, { setStatus }) => {
      try {
        await resendConfirmation(values.username);
        navigate("/auth/confirm-signup");
      } catch (ex) {
        snackbar("Error: " + ex.response?.data?.message, { variant: "error" });
        setStatus("Error: " + ex.response?.data?.message);
      }
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Send Confirmation Link
        </Typography>
        <Box
          component="form"
          noValidate
          sx={{
            width: "100%",
            mt: 1,
          }}
          onSubmit={formik.handleSubmit}
        >
          <Box marginY={2} hidden={formik.status ? false : true}>
            <Alert severity="error">{formik.status}</Alert>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                autoFocus
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                margin="normal"
                {...formik.getFieldProps("username")}
              />
            </Grid>
          </Grid>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            pending={formik.isSubmitting}
          >
            Send Confirmation Link
          </LoadingButton>
          <Grid container justify="space-between">
            <Grid item>
              <Link component={RouterLink} to="/auth/signin" variant="body2">
                Back to Signin
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default ResendConfirmation;
