import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { confirmSignup } from "../../services/authService";
import { Box } from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";

const ConfirmSignup = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const { enqueueSnackbar: snackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      username: params.get("username") || "",
      confirmationCode: params.get("confirmationCode") || "",
    },
    onSubmit: async (values, { setStatus }) => {
      try {
        await confirmSignup(values.username, values.confirmationCode);
        navigate("/auth/signin");
      } catch (ex) {
        snackbar("Error: " + ex.response?.data?.message, { variant: "error" });
        setStatus("Error: " + ex.response?.data?.message);
      }
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Confirm Signup
        </Typography>
        <Box
          component="form"
          noValidate
          sx={{
            width: "100%",
            mt: 1,
          }}
          onSubmit={formik.handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                autoFocus
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                margin="normal"
                {...formik.getFieldProps("username")}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="confirmationCode"
              label="Confirmation Code"
              name="confirmationCode"
              autoComplete="confirmationCode"
              margin="normal"
              {...formik.getFieldProps("confirmationCode")}
            />
          </Grid>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            pending={formik.isSubmitting}
          >
            Confirm Signup
          </LoadingButton>
          <Grid container justify="space-between">
            <Grid item>
              <Link
                component={RouterLink}
                to="/auth/resend-confirmation"
                variant="body2"
              >
                Resend Confirmation Link
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default ConfirmSignup;
