import Axios from "axios";

const url = "https://mcs6dyhpu0.execute-api.us-east-1.amazonaws.com/Stage/";
const tokenKey = "token";

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await refreshToken();
        originalRequest.headers["Authorization"] =
          Axios.defaults.headers.common["Authorization"];
        return Axios(originalRequest);
      } catch (ex) {}
    }
    return Promise.reject(error);
  }
);

export const setToken = () => {
  try {
    const token = JSON.parse(localStorage.getItem(tokenKey));
    Axios.defaults.headers.common["Authorization"] = token.idToken.jwtToken;
  } catch (ex) {}
};

setToken();

export const refreshToken = async () => {
  const token = JSON.parse(localStorage.getItem(tokenKey));
  const Username = token.idToken.payload.email;
  const refreshToken = token.refreshToken;
  const { data } = await Axios.put(url + "SignIn", { Username, refreshToken });
  localStorage.setItem(tokenKey, JSON.stringify(data));
  Axios.defaults.headers.common["Authorization"] = data.idToken.jwtToken;
};

export const signin = async (Username, Password) => {
  const { data } = await Axios.post(url + "SignIn", { Username, Password });
  localStorage.setItem(tokenKey, JSON.stringify(data));
};

export const signup = (
  Username,
  Password,
  FirstName,
  LastName,
  PhoneNumber
) => {
  return Axios.post(url + "SignUp", {
    type: "SignUp",
    Username,
    Password,
    UserAttributes: [
      { Name: "given_name", Value: FirstName },
      { Name: "family_name", Value: LastName },
      { Name: "phone_number", Value: PhoneNumber },
    ],
  });
};

export const confirmSignup = (Username, ConfirmationCode) => {
  return Axios.post(url + "SignUp", {
    type: "confirmSignUp",
    Username,
    ConfirmationCode,
  });
};

export const forgotPassword = (Username) => {
  return Axios.post(url + "ForgetPassword", { Username });
};

export const setNewPassword = (Username, newPassword, verificationCode) => {
  return Axios.put(url + "ForgetPassword", {
    Username,
    newPassword,
    verificationCode,
  });
};

export const getUser = () => {
  const token = localStorage.getItem(tokenKey);
  if (token === null) {
    return null;
  }
  return JSON.parse(localStorage.getItem(tokenKey)).idToken.payload;
};

export const resendConfirmation = (Username) => {
  return Axios.post(url + "SignUp", { type: "resendConfirmation", Username });
};
