import { Box, Button, TextField } from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useRecoilState } from "recoil";
import { updateMeme } from "../services/memeService";
import { uploadMemeImage } from "../services/s3Service";
import { memesState } from "../state";
import { replaceItemAtIndex } from "../utils";
import * as Yup from "yup";
import { Save } from "@material-ui/icons";

const EditMeme = ({ meme, setEdit }) => {
  const [memes, setMemes] = useRecoilState(memesState);
  const index = memes.findIndex((listItem) => listItem === meme);
  const { enqueueSnackbar: snackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      content: meme.Content,
      imageName: "",
      image: meme.ImageUrl,
    },
    validationSchema: Yup.object({
      content: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        if (values.imageName !== "") {
          const { data: imageUrl } = await uploadMemeImage(
            `test/${values.imageName}`,
            values.image
          );
          values.image = imageUrl;
        }
        const { data: updatedMeme } = await updateMeme(
          meme.Id,
          values.content,
          values.image
        );
        setMemes(replaceItemAtIndex(memes, index, updatedMeme));
        snackbar("Meme updated successfully!", { variant: "success" });
      } catch (ex) {
        snackbar("Error: " + ex.response?.data?.message, { variant: "error" });
      } finally {
        setEdit(false);
      }
    },
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = () => {
      formik.setFieldValue("image", fileReader.result);
      formik.setFieldValue("imageName", file.name);
    };
    fileReader.readAsDataURL(file);
  };

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <TextField
        margin="normal"
        autoComplete="content"
        name="content"
        required
        fullWidth
        id="content"
        label="Content"
        {...formik.getFieldProps("content")}
        helperText={formik.touched.content && formik.errors.content}
        error={formik.touched.content && Boolean(formik.errors.content)}
      />
      <Button
        variant="contained"
        startIcon={<Save />}
        component="label"
        color="inherit"
        fullWidth
      >
        Upload
        <input
          type="file"
          hidden
          accept="image/*"
          onChange={handleImageChange}
        />
      </Button>
      <Box
        component="img"
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          objectFit: "cover",
          marginTop: 1,
        }}
        src={formik.values.image}
      />
      <LoadingButton
        sx={{ mt: 3, mb: 2 }}
        type="submit"
        fullWidth
        variant="contained"
        pending={formik.isSubmitting}
      >
        Update Meme
      </LoadingButton>
    </form>
  );
};

export default EditMeme;
