import * as React from "react";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Box } from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import {
  setNewPassword,
  getUser,
  setToken,
  signin,
} from "../../services/authService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { userState } from "../../state";
import { useSetRecoilState } from "recoil";

const ResetPassword = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const setUser = useSetRecoilState(userState);
  const { enqueueSnackbar: snackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      username: params.get("username") || "",
      password: "",
      confirmPassword: "",
      confirmationCode: params.get("confirmationCode") || "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .email("Must be a valid email")
        .required("Required"),
      password: Yup.string()
        .required("Required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/,
          "Password must contain 1 capital letter. 1 small letter. 1 number. 1 special character"
        ),
      confirmPassword: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      confirmationCode: Yup.number().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        await setNewPassword(
          values.username,
          values.password,
          values.confirmationCode
        );
        snackbar("Password changed successfully!", { variant: "success" });
        await signin(values.username, values.password);
        setToken();
        setUser(getUser());
        snackbar("Successfully signed in!", { variant: "success" });
      } catch (ex) {
        snackbar("Error: " + ex.response?.data?.message, { variant: "error" });
      }
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Box
          component="form"
          noValidate
          sx={{
            width: "100%",
            mt: 3,
          }}
          onSubmit={formik.handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                {...formik.getFieldProps("username")}
                helperText={formik.touched.username && formik.errors.username}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="New Password"
                type="password"
                id="password"
                autoComplete="current-password"
                {...formik.getFieldProps("password")}
                helperText={formik.touched.password && formik.errors.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                {...formik.getFieldProps("confirmPassword")}
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="confirmationCode"
                label="Confirmation Code"
                name="confirmationCode"
                autoComplete="confirmationCode"
                {...formik.getFieldProps("confirmationCode")}
                helperText={
                  formik.touched.confirmationCode &&
                  formik.errors.confirmationCode
                }
                error={
                  formik.touched.confirmationCode &&
                  Boolean(formik.errors.confirmationCode)
                }
              />
            </Grid>
          </Grid>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            pending={formik.isSubmitting}
          >
            Confirm Reset
          </LoadingButton>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Link
                component={RouterLink}
                variant="body2"
                to="/auth/forgot-password"
              >
                Resend Link
              </Link>
            </Grid>
            <Grid item>
              <Link component={RouterLink} to="/auth/signin" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default ResetPassword;
