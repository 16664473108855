import React, { useEffect } from "react";
import { Box, CircularProgress, Container } from "@material-ui/core";
import Meme from "../../components/Meme";
import { getAllMemes } from "../../services/memeService";
import NewMeme from "../../components/NewMeme";
import { useRecoilState } from "recoil";
import { memesState } from "../../state";

const Dashboard = () => {
  const [memes, setMemes] = useRecoilState(memesState);

  useEffect(() => {
    const fetchMemes = async () => {
      try {
        const { data } = await getAllMemes();
        setMemes(data);
      } catch (ex) {}
    };
    fetchMemes();
  }, [setMemes]);

  return (
    <Container maxWidth="sm">
      <NewMeme />
      {memes.length === 0 ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : null}
      {memes.map((meme) => (
        <Meme key={meme.Id} meme={meme} />
      ))}
    </Container>
  );
};

export default Dashboard;
