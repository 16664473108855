import Axios from "axios";

const url =
  "https://1v4272pqg8.execute-api.us-east-1.amazonaws.com/Stage/S3Upload";

export const uploadMemeImage = (key, base64String) => {
  return Axios.post(url, { key, imageType: "Meme", base64String });
};

export const uploadProfileImage = (key, base64String) => {
  return Axios.post(url, { key, imageType: "Profile", base64String });
};
