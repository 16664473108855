import { ConfirmProvider } from "material-ui-confirm";
import { useRoutes } from "react-router-dom";
import { useRecoilValue } from "recoil";
import routes from "./routes";
import { userState } from "./state";

const App = () => {
  const user = useRecoilValue(userState);
  const routing = useRoutes(routes(user));

  return <ConfirmProvider>{routing}</ConfirmProvider>;
};

export default App;
