import {
  Avatar,
  Badge,
  Box,
  Container,
  IconButton,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import {
  DatePicker,
  LoadingButton,
  LocalizationProvider,
} from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useRecoilState } from "recoil";
import { updateProfile } from "../../services/profileService";
import { uploadProfileImage } from "../../services/s3Service";
import { userState } from "../../state";

const Profile = () => {
  const [user, setUser] = useRecoilState(userState);
  const { enqueueSnackbar: snackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      given_name: "" || user.given_name,
      family_name: "" || user.family_name,
      gender: "" || user.gender,
      imageName: "",
      ImageUrl: "" || user.ImageUrl,
      dob: "" || user.dob,
      country: "" || user.country,
    },
    onSubmit: async (values) => {
      try {
        if (values.imageName !== "") {
          const { data } = await uploadProfileImage(
            `test/${values.imageName}`,
            values.ImageUrl
          );
          values.ImageUrl = data;
        }
        await updateProfile(user.email, values);
        setUser((user) => {
          return { ...user, ...values };
        });
        snackbar("Profile updated successfully!", { variant: "success" });
      } catch (ex) {
        snackbar("Error: " + ex.response?.data?.message, { variant: "error" });
      }
    },
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = () => {
      formik.setFieldValue("ImageUrl", fileReader.result);
      formik.setFieldValue("imageName", file.name);
    };
    fileReader.readAsDataURL(file);
  };

  return (
    <Container maxWidth="xs">
      <Box
        component="form"
        noValidate
        sx={{
          width: "100%",
          mt: 1,
        }}
        onSubmit={formik.handleSubmit}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Badge
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            badgeContent={
              <IconButton component="label" size="small">
                <Edit />{" "}
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </IconButton>
            }
          >
            <Avatar
              sx={{
                width: (theme) => theme.spacing(10),
                height: (theme) => theme.spacing(10),
              }}
              component="label"
              src={formik.values.ImageUrl}
            />
          </Badge>
        </Box>
        <TextField
          margin="normal"
          fullWidth
          id="firstName"
          label="First Name"
          name="firstName"
          autoComplete="firstName"
          {...formik.getFieldProps("given_name")}
        />
        <TextField
          margin="normal"
          fullWidth
          id="lastName"
          label="Last Name"
          name="lastName"
          autoComplete="lastName"
          {...formik.getFieldProps("family_name")}
        />
        <TextField
          select
          margin="normal"
          fullWidth
          id="gender"
          defaultValue="male"
          label="Gender"
          {...formik.getFieldProps("gender")}
        >
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
        </TextField>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date of Birth"
            value={formik.values.dob}
            onChange={(newValue) => formik.setFieldValue("dob", newValue)}
            renderInput={(props) => (
              <TextField {...props} fullWidth error={false} margin="normal" />
            )}
          />
        </LocalizationProvider>
        <TextField
          fullWidth
          margin="normal"
          id="country"
          name="country"
          label="Country"
          autoComplete="country"
          {...formik.getFieldProps("country")}
        />
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          pending={formik.isSubmitting}
        >
          Update Profile
        </LoadingButton>
      </Box>
    </Container>
  );
};

export default Profile;
