import { Outlet } from "react-router";
import TopBar from "../components/TopBar";

const MainLayout = () => {
  return (
    <>
      <TopBar />
      <Outlet />
    </>
  );
};

export default MainLayout;
