import { Box, Button, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { createMeme } from "../services/memeService";
import { uploadMemeImage } from "../services/s3Service";
import SaveIcon from "@material-ui/icons/Save";
import { LoadingButton } from "@material-ui/lab";
import { useRecoilState } from "recoil";
import { memesState } from "../state";
import { addItemAtStart } from "../utils";

const NewMeme = () => {
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [memes, setMemes] = useRecoilState(memesState);

  const formik = useFormik({
    initialValues: {
      content: "",
      imageName: "",
      image: "",
    },
    validationSchema: Yup.object({
      content: Yup.string().required("Required"),
      image: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        const { data: imageUrl } = await uploadMemeImage(
          `test/${values.imageName}`,
          values.image
        );
        const { data: newMeme } = await createMeme(values.content, imageUrl);
        setMemes(addItemAtStart(memes, newMeme));
        snackbar("Meme added successfully!", { variant: "success" });
        formik.resetForm();
      } catch (ex) {
        snackbar("Error: " + ex.response?.data?.message, { variant: "error" });
      }
    },
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = () => {
      formik.setFieldValue("image", fileReader.result);
      formik.setFieldValue("imageName", file.name);
    };
    fileReader.readAsDataURL(file);
  };

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <TextField
        margin="normal"
        autoComplete="content"
        name="content"
        required
        fullWidth
        id="content"
        label="Content"
        {...formik.getFieldProps("content")}
        helperText={formik.touched.content && formik.errors.content}
        error={formik.touched.content && Boolean(formik.errors.content)}
      />
      <Button
        variant="contained"
        startIcon={<SaveIcon />}
        component="label"
        color="inherit"
        fullWidth
      >
        Upload
        <input
          type="file"
          hidden
          accept="image/*"
          onChange={handleImageChange}
        />
      </Button>
      {formik.touched.image && formik.errors.image}
      {formik.values.image === "" ? (
        ""
      ) : (
        <Box
          component="img"
          sx={{
            width: "100%",
            objectFit: "cover",
            marginTop: 1,
          }}
          src={formik.values.image}
        />
      )}
      <LoadingButton
        sx={{ mt: 3, mb: 2 }}
        type="submit"
        fullWidth
        variant="contained"
        pending={formik.isSubmitting}
      >
        Add Meme
      </LoadingButton>
    </form>
  );
};

export default NewMeme;
